import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlotterConfig } from 'app/models/settings.model';

import * as fromSession from '../reducers/session.reducer';

export const selectSessionState = createFeatureSelector<fromSession.SessionState>(fromSession.sessionKey);

export const selectIsAppInitialized = createSelector(selectSessionState, (state: fromSession.SessionState) => state.isAppInitialized);

export const selectIsUserAuthenticated = createSelector(selectSessionState, (state: fromSession.SessionState) => state.isUserAuthenticated);

export const selectIsDaemonUp = createSelector(selectSessionState, (state: fromSession.SessionState) => state.isDaemonUp);

export const selectUserConfiguration = createSelector(selectSessionState, (state: fromSession.SessionState) => state.userConfiguration);

export const selectCredentials = createSelector(selectSessionState, (state: fromSession.SessionState) => state.credentials);

export const selectBanner = createSelector(selectSessionState, (state: fromSession.SessionState) => state.banner);

export const selectFabricQuery = createSelector(selectSessionState, (state: fromSession.SessionState) => ({
  fabricState: state.printableDataSession.currentCanvasState.stateAsString,
  plotterConfig: normalizePlotterConfig(state.userConfiguration.plotter),
  printableDataId: state.printableDataSession.selectedPrintableDataId,
  userConfiguration: state.userConfiguration,
}));

export const selectLoading = (props: { loadingType: fromSession.SessionLoading }) =>
  createSelector(selectSessionState, (state: fromSession.SessionState) => state.loading.includes(props.loadingType));

const normalizePlotterConfig = (plotter: string): PlotterConfig => {
  let config: PlotterConfig;
  switch (plotter) {
    case 'PNN':
      config = { unit: 0.0255, switchAxis: false, mirror: true };
      break;
    case 'PR':
      config = { unit: 0.025, switchAxis: true, mirror: false };
      break;
    case 'PN':
    default:
      config = { unit: 0.025, switchAxis: false, mirror: true };
      break;
  }

  return config;
};
export const selectIsTabletMode = createSelector(selectSessionState, (state: fromSession.SessionState) => state.isTabletMode);
export const selectIsSidenavOpen = createSelector(selectSessionState, (state: fromSession.SessionState) => state.isSidenavOpen);
4;
