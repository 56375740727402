import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'user-menu-dumb',
  templateUrl: './user-menu-dumb.component.html',
  styleUrls: ['./user-menu-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuDumbComponent {
  @Input() loadingLogout: boolean;
  @Input() isDaemonUp: boolean;

  @Output() readonly openProfileDialogEvent = new EventEmitter<void>();
  @Output() readonly logoutEvent = new EventEmitter<void>();
  @Output() readonly openTutorial = new EventEmitter<void>();

  logout() {
    this.logoutEvent.emit();
  }

  openProfileDialog() {
    this.openProfileDialogEvent.emit();
  }

  navigate() {
    this.openTutorial.emit();
  }
}
