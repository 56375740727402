import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FabricJSON } from 'app/models/fabric.model';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService, PrintingService } from '../../app-services';
import * as PrintingActions from '../actions/printing.actions';
import { selectFabricQuery } from '../selector/session.selectors';
import { isRuler } from '../../utils/fabric.utils';
import { selectCurrentPrinter } from '../selector/user.selectors';
import { NotificationType } from 'app/models/notification-types.const';
import { refreshUserData } from '../actions/user.actions';
import { PrintFailQuery } from 'app/models/printing.model';

@Injectable()
export class PrintingEffects {
  startPrinting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.startPrinting),
      concatLatestFrom(() =>
        this.store.select(selectFabricQuery).pipe(
          map(fabricQuery => {
            const state: FabricJSON = JSON.parse(fabricQuery.fabricState);
            state.objects = state.objects.filter(obj => !isRuler(obj));
            fabricQuery.fabricState = JSON.stringify(state);

            return fabricQuery;
          }),
        ),
      ),
      switchMap(([, fabricQuery]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userConfiguration, ...query } = fabricQuery;

        return this.printingService
          .printData({
            ...query,
            printer: userConfiguration.printer,
          })
          .pipe(
            map(() => PrintingActions.printHpglCompleted()),
            catchError((error: PrintFailQuery) => of(PrintingActions.printError({ error }))),
          );
      }),
    );
  });

  testPrinting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.testPrinting),
      concatLatestFrom(() => this.store.select(selectCurrentPrinter)),
      switchMap(([action, currentPrinter]) => this.printingService.testPrinting(action.hpgl, currentPrinter)),
      map(() => PrintingActions.printHpglCompleted()),
      catchError((error: PrintFailQuery) => of(PrintingActions.printError({ error }))),
    );
  });

  printFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PrintingActions.printError),
        switchMap(errorAction =>
          this.printingService.postPrintFail(errorAction.error).pipe(
            finalize(() => {
              this.notificationService.notify(NotificationType.PRINT_FAILED, 5000);
            }),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  printHpglCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.printHpglCompleted),
      tap(() => {
        this.notificationService.notify(NotificationType.PRINT_COMPLETED, 5000);
      }),
      map(() => refreshUserData()),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly printingService: PrintingService,
    private readonly notificationService: NotificationService,
  ) {}
}
