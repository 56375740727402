@if (isDaemonUp) {
  <button mat-icon-button class="daemon-status-icon">
    <mat-icon class="user-menu-icon" svgIcon="ic_signal_wifi_4_bar_24px" [matTooltip]="'daemon-connected' | translate"></mat-icon>
  </button>
} @else {
  <button mat-icon-button class="daemon-status-icon">
    <mat-icon class="user-menu-icon" svgIcon="ic_signal_wifi_off_24px" [matTooltip]="'daemon-disconnected' | translate"></mat-icon>
  </button>
}
<button mat-icon-button (click)="openProfileDialog()">
  <mat-icon [matTooltip]="'profile' | translate" svgIcon="ic_settings_24px"></mat-icon>
</button>

<button mat-icon-button (click)="navigate()">
  <mat-icon [matTooltip]="'tutoriel' | translate" svgIcon="ic_help_24px"></mat-icon>
</button>

@if (!loadingLogout) {
  <button mat-icon-button (click)="logout()">
    <mat-icon [matTooltip]="'logout' | translate" svgIcon="ic_power_settings_new_24px"></mat-icon>
  </button>
} @else {
  <button mat-icon-button>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </button>
}
