import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { Piece } from '../models/printable-data.model';
import { FilterFormFields } from 'app/models/filter-form';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'max-try',
  templateUrl: './max-try.component.html',
  styleUrls: ['./max-try.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaxTryComponent implements OnInit {
  @Input() allowedPrintedWindows: number;
  @Input() allowedPrintedPPF: number;
  @Input() loadedPieces: Piece[];
  @Input() currentForm: FilterFormFields;
  @Input() selectedPrinter: string;
  @Input() printInProgress: boolean;

  @Output() readonly startCut = new EventEmitter<void>();

  currentLang$: Observable<string>;

  constructor(
    private readonly translateService: TranslateService,
    private readonly snackBarService: MatSnackBar,
  ) {}

  ngOnInit() {
    this.currentLang$ = this.translateService.onLangChange.pipe(map(langEventChange => langEventChange.lang));
  }

  startCutting() {
    this.selectedPrinter
      ? this.startCut.emit()
      : this.translateService.get('choose-printer').subscribe((message: string) => {
          this.snackBarService.open(message, 'OK', { duration: 10000 });
        });
  }
}
