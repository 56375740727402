<form [formGroup]="plotterTabForm" novalidate>
  <mat-form-field>
    <mat-label translate="connection"></mat-label>
    <mat-select [matTooltip]="plotterTabForm.controls['daemonHost']?.value" formControlName="daemonHost">
      <mat-select-trigger>
        {{ plotterTabForm.controls['daemonHost']?.value }}
      </mat-select-trigger>
      @for (address of daemonAddressList; track address) {
        <mat-option [value]="address.hostName + ':' + address.port">
          {{ address.name + ' (' + address.hostName + ':' + address.port + ')' }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="printer"></mat-label>
    <mat-select (openedChange)="updatePrinterList()" [matTooltip]="plotterTabForm.controls['printer'].value" formControlName="printer">
      @for (printer of printerList; track printer) {
        <mat-option [value]="printer.name">
          {{ printer.name + '(' + printer.portName + ')' }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="plotter"></mat-label>
    <mat-select [matTooltip]="plotterTabForm.controls['plotter'].value | translate" formControlName="plotter">
      @for (plotter of plotterList; track plotter) {
        <mat-option [value]="plotter">
          {{ plotter | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="film-width">
    <mat-label translate="film-width"></mat-label>
    <input matInput type="number" min="0" max="600" formControlName="filmWidth" (blur)="checkValue(plotterTabForm.controls['filmWidth'])" />
    <span matSuffix>cm</span>
  </mat-form-field>

  <div>
    <button [matTooltip]="('test' | translate) + ' PLT'" [disabled]="!isDaemonUp || !currentPrinter" mat-icon-button (click)="onTestPrint(printerType.PLT)">
      <mat-icon svgIcon="ic_print_24px"></mat-icon>
    </button>
    <button [matTooltip]="('test' | translate) + ' HPGL'" [disabled]="!isDaemonUp || !currentPrinter" mat-icon-button (click)="onTestPrint(printerType.HPGL)">
      <mat-icon svgIcon="ic_print_24px"></mat-icon>
    </button>
  </div>
</form>
