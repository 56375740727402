import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { PrinterDetails, PrintFailQuery, PrintingList, PrintingQuery, printPiecesQuery, PrintResponse } from '../models/printing.model';
import { Store } from '@ngrx/store';
import { selectCredentials } from 'app/store/selector/session.selectors';
import { Credentials } from 'app/models/user.model';

@Injectable()
export class PrintingService {
  private readonly printingUrl = 'http://127.0.0.1:3000/';
  route = {
    ids: 'ids',
    modelIds: 'model-ids', // needed
    adminIds: 'admin-ids',
    startDate: 'start-date', // needed
    endDate: 'end-date', // needed
    offset: 'offset',
    limit: 'limit', // needed
    exclude: 'exclude',
    next: 'next',
  };

  constructor(
    private readonly store: Store,
    private _http: HttpClient,
    private readonly httpBackend: HttpBackend,
    private readonly httpWithoutInterceptor: HttpClient,
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  getAvailablePrinters(): Observable<PrinterDetails[]> {
    return this.httpWithoutInterceptor.get<PrinterDetails[]>(`${this.printingUrl}printer-list`);
  }

  getPrintingList(query: PrintingQuery = {}): Observable<PrintingList> {
    const url =
      '/api/printings/list?' +
      Object.keys(query)
        .map(k => (Array.isArray(query[k]) ? query[k].map((value, index) => `${this.route[k]}[${index}]=${value}`).join('&') : `${this.route[k]}=${query[k]}`))
        .join('&');

    return this._http.get<{ data: PrintingList; statusCode: number }>(url).pipe(map(response => response.data));
  }

  printData(query?: printPiecesQuery): Observable<PrintResponse> {
    return this.store.select(selectCredentials).pipe(
      first(),
      switchMap((credentials: Credentials) => {
        return this.httpWithoutInterceptor
          .post<PrintResponse>(`${this.printingUrl}proxy/printings/print`, query, { headers: { Authorization: `Bearer ${credentials.token}` } })
          .pipe(
            catchError(error =>
              throwError(() => ({
                message: error.message,
                tracelog: JSON.stringify(error),
              })),
            ),
          );
      }),
    );
  }

  testPrinting(data: string | Buffer, printer: string): Observable<PrintResponse> {
    return this.httpWithoutInterceptor.post<PrintResponse>(`${this.printingUrl}print`, { data, printer }).pipe(
      catchError(error =>
        throwError(() => ({
          message: error.message,
          tracelog: JSON.stringify(error),
        })),
      ),
    );
  }

  postPrintFail(query?: PrintFailQuery): Observable<string> {
    const url = '/api/printings/print-fail';

    return this._http
      .post(url, query?.message ? query : this.buildPrintFailedQuery(query as unknown as Error), { observe: 'response', responseType: 'text' })
      .pipe(map((res: HttpResponse<string>) => res.body));
  }

  private buildPrintFailedQuery(error: Error): PrintFailQuery {
    return {
      message: error.message,
      tracelog: error.stack,
    };
  }
}
